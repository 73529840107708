import PProviderService from '@/services/api/Provider/PProvider'
// import GGoogleService from '@/services/api/General/GGoogle'
// import GUserAccountService from '@/services/api/General/GUserAccount'
// import RulesService from '@/services/rules'
import * as d3 from 'd3'
import canvg from 'canvg'

/* eslint-disable */ 
export default {
  name: 'v-view-provider-report',
  data: () => ({
    showFueraFaena:false,
    show:false,
    busy: false,
    isFaena:false,
    isOutFaena:false,
    affirmative: 0,
    survey: null,
    surveyFueraFaena:null,
    pProvider: {
      scores: [{
        score: 0.00,
      }]
    },
    pProviderSurvey: {
      questions: [],
    },
    pProviderSurveyFaena: {
      questions: [],
    },
    dialogs: {
      review: false,
      reviewFaena: false,
    },
  }),
  methods: {

    /**
     * 
     */
    getArc(height, width) {
      return d3.arc().innerRadius(0).outerRadius(Math.min(height, width) / 2 - 1)
    },

    /**
     * 
     */
    getColor() {
      return d3.scaleOrdinal().domain(['Cumple', 'No cumple']).range(this.getColorQuantize())
    },

    /**
     * 
     */
    getColorBases() {
      return [
        "#0033a0",
        "#f5a800"
      ]
    },

    /**
     * 
     */
    getColorQuantize() {
      return d3.quantize(t => d3.interpolateRgbBasis(this.getColorBases())(t), 2)
    },

    /**
     * Si faena viene true significa que 
     */
    getData(title) {
      
      let section = this.survey.find(section => section.title == title)
      let affirmatives  = section.questions.reduce(this.reduceAffirmative, 0)
      let negatives     = section.questions.reduce(this.reduceNegative, 0)
      return [
        { name: 'Cumple', value: affirmatives, color: "#0033a0" },
        { name: 'No cumple', value: negatives, color: "#f5a800" },
      ]
    },
    getDataOutFaena(title) {
      let section = this.surveyFueraFaena.find(section => section.title == title)
      let affirmatives  = section.questions.reduce(this.reduceAffirmative, 0)
      let negatives     = section.questions.reduce(this.reduceNegative, 0)
      return [
        { name: 'Cumple', value: affirmatives, color: "#0033a0" },
        { name: 'No cumple', value: negatives, color: "#f5a800" },
      ]
    },
    /**
     * 
     */
    getLegend(svg, color) {
      return svg.selectAll('.legend').data(color.domain()).enter()
    },

    /**
     * 
     */
    getPie() {
      return d3.pie().sort(null).value(d => d.value)
    },

    /**
     * 
     */
    getProvider() {
      let pProviderId = this.providerId || this.$provider.id
      PProviderService.getById(pProviderId).then(res => {
        this.pProvider = res
      })
    },


    /**
     * 
     */
    getSubData(title) {
      let section = this.survey.find(section => section.title == title)
      let affirmatives  = 0
      let negatives     = 0
      section.subcategories.forEach(subcategory => {
        affirmatives  += subcategory.questions.reduce(this.reduceAffirmative, 0)
        negatives     += subcategory.questions.reduce(this.reduceNegative, 0)
      })
      return [
        { name: 'Cumple', value: affirmatives, color: "#005b09" },
        { name: 'No cumple', value: negatives, color: "#d32f2f" },
      ]
    },
    getSubDataOutFaena(title) {
      let section = this.surveyFueraFaena.find(section => section.title == title)
      let affirmatives  = 0
      let negatives     = 0
      section.subcategories.forEach(subcategory => {
        affirmatives  += subcategory.questions.reduce(this.reduceAffirmative, 0)
        negatives     += subcategory.questions.reduce(this.reduceNegative, 0)
      })
      return [
        { name: 'Cumple', value: affirmatives, color: "#005b09" },
        { name: 'No cumple', value: negatives, color: "#d32f2f" },
      ]
    },

    /**
     * 
     */
    getSurvey() {
      this.busy = true
      let pProviderId = this.providerId || this.$provider.id
      PProviderService.getSurvey(pProviderId).then(res => {
        if(res[0].faena==false) this.survey = res[0].data 
        if(res[1].faena==false) this.survey = res[1].data       
        //this.removeAllOutFaena()
        //console.log("removeAllOutFaena")
        this.busy = false
        this.renderChartIntegratedManagementSystem()
        this.renderChartPrograms()
        this.renderChartSpecialCriteriaForCompetitiveness()
        this.renderChartHSEC()
        this.renderChartSupplyPlatformsAndTenders()
        this.renderChartTechnicalCapacity()
        this.renderChartTechnology()
      }) 
    },

    getSurveyFueraFaena() {
      this.busy = true
      let pProviderId = this.providerId || this.$provider.id
      PProviderService.getSurvey(pProviderId).then(res => {
        if(res[0].faena==true) this.surveyFueraFaena = res[0].data
        if(res[1].faena==true) this.surveyFueraFaena = res[1].data
        //this.removeAll()
        //console.log("removeAll")
        this.busy = false
        this.renderChartGRP()
        this.renderChartCOVID()
        this.renderChartEmergencyPlan()
        this.renderChartPCCYCAC()
        this.renderChartEUA()
        this.renderChartPHPL()
      })
    },

   

    /**
     * 
     */
    reduceAffirmative(total, question) {
      if(question.yesOrNo) {
        this.affirmative += 1
      }
      return total += question.yesOrNo ? 1 : 0
    },

    /**
     * 
     */
    reduceNegative(total, question) {
      return total += question.yesOrNo ? 0 : 1
    },

    /**
     * 
     */
    removeAll() {
      d3.select('#chart-1').remove()
      d3.select('#chart-2').remove()
      d3.select('#chart-3').remove()
      d3.select('#chart-4').remove()
      d3.select('#chart-5').remove()
      d3.select('#chart-6').remove()
      d3.select('#chart-7').remove()
    },
    removeAllOutFaena() {
      d3.select('#chart-11').remove()
      d3.select('#chart-12').remove()
      d3.select('#chart-13').remove()
      d3.select('#chart-14').remove()
      d3.select('#chart-15').remove()
      d3.select('#chart-16').remove()
    },


    /**
     * 
     */
    renderChartIntegratedManagementSystem() {
      let data = this.getData('Sistema Integrado de Gestión')
      let name = '#chart-1'
      this.renderChart(name, data)
    },

    /**
     * 
     */
    renderChartPrograms() {
      let data = this.getData('Programas')
      let name = '#chart-2'
      this.renderChart(name, data)
    },

    /**
     * 
     */
    renderChartSupplyPlatformsAndTenders() {
      let data = this.getData('Plataformas de Abastecimiento y Licitaciones')
      let name = '#chart-3'
      this.renderChart(name, data)
    },

    /**
     * 
     */
    renderChartHSEC() {
      let data = this.getSubData('Global HSEC (Health / Safety / Environment / Community)')
      let name = '#chart-4'
      this.renderChart(name, data)
    },    

    /**
     * 
     */
    renderChartSpecialCriteriaForCompetitiveness() {
      let data = this.getData('Criterios especiales para la competitividad')
      let name = '#chart-5'
      this.renderChart(name, data)
    },    


    /**
     * 
     */
    renderChartTechnicalCapacity() {
      let data = this.getData('Capacidad Técnica')
      let name = '#chart-6'
      this.renderChart(name, data)
    },

    /**
     * 
     */
    renderChartTechnology() {
      let data = this.getData('Tecnología')
      let name = '#chart-7'
      this.renderChart(name, data)
    },

/**\
     * RENDERS SEGUNDA ENCUESTA
     * console.log('render fuera de faena')
        
     */
  renderChartGRP() {
    let data = this.getDataOutFaena('Gestión de Riesgos de Procesos (GRP)')
    let name = '#chart-11'
    this.renderChart(name, data)
  },
  renderChartCOVID() {
    let data = this.getDataOutFaena('Protocolos de cumplimiento Covid-19')
    let name = '#chart-12'
    this.renderChart(name, data)
  },
  renderChartEmergencyPlan() {
    let data = this.getDataOutFaena('Plan de Emergencia')
    let name = '#chart-13'
    this.renderChart(name, data)
  },
  renderChartPCCYCAC() {
    let data = this.getDataOutFaena('Protocolos de conducta comercial y convivencia armónica con comunidades')
    let name = '#chart-14'
    this.renderChart(name, data)
  },
  renderChartEUA() {
    let data = this.getDataOutFaena('Ubicación empresas de alojamiento')
    let name = '#chart-15'
    this.renderChart(name, data)
  },
  renderChartPHPL() {
    let data = this.getSubDataOutFaena('Proceso de Habilitación Proveedores Locales')
    let name = '#chart-16'
    this.renderChart(name, data)
  },
  /**
   * 
   */
  getSvg(name = '#chart') {
    return d3.select(name).append('svg').attr('viewBox', '-100 -110 500 190')
  },

  /**
   * 
   */
  renderChart(name, data) {
    //console.log('render', name)

    // required      
    let pie     = this.getPie()
    let color   = this.getColor()
    let width   = 500
    let height  = 150
    let svg     = this.getSvg(name)
    let arc     = this.getArc(height, width)
    let arcs    = pie(data)

    // main
    svg
      .append("g")
      .attr("stroke", "white")
      .selectAll("path")
      .data(arcs)
      .join("path")
      .attr("fill", this.setFillName(color))
      .attr("d", arc)
      .append("title")
      .text(this.setTextNameWithValue())
  
    // legend
    svg
      .append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 15)
      .attr("text-anchor", "middle")
      .selectAll("text")
      .data(arcs)
      .join("text")
      .attr('transform', this.transformLegend(arc))
      .call(text => text.append("tspan")
        .attr("y", "-0.8em")
        .attr("font-weight", "bold")
        .attr("fill", this.setFillNameWithAngle(color))
        .text(d => d.data.value))
      .call(text => text.append("tspan")
        .attr("x", 0)
        .attr("y", "0.2em")
        .attr("fill-opacity", 0.7)
        .attr("fill", this.setFillNameWithAngle(color))
        .text(this.setTextPercentWithAngle()))
    
    // legends
    var legendRectSize  = 10
    var legendSpacing   = 5
    var enteringLabels  = this.getLegend(svg, color)

    // legend
    var lg = enteringLabels.append('g') 
    .attr('class', 'legend') 
    .attr('transform', this.setTransformWithColor(color, legendRectSize, legendSpacing))
    
    // legend rectangle
    lg
      .append('rect') 
      .attr('width', legendRectSize) 
      .attr('height', legendRectSize) 
      .style('fill', this.setFillByNumber(color)) 
      .style('stroke', color)
    
    // labels
    var labelGroups = enteringLabels.append('g').attr('class', 'label').data(arcs)
    var lines = labelGroups.append('line')
      .attr('x1', d => arc.centroid(d).shift())
      .attr('y1', d => arc.centroid(d).pop())
      .attr('x2', this.setMidAngleWithCos(arc))
      .attr('y2', this.setMidAngleWithSin(arc))
      .attr('class', 'label-line')
      .attr('stroke', (d, i) => color(i - 1))
      .filter(this.setFilterByAngle())
      .attr('style', 'display: none;')
    
    // legend rectangle text
    lg
      .append('text')                                    
      .attr('x', legendRectSize + legendSpacing)
      .attr('y', legendRectSize)
      .text(d => d)

    // render chart
    return svg.node()
  },

  /**
   * 
   */
  setMidAngleWithCos: arc => d => {
    const _d        = arc.centroid(d)
    const midAngle  = Math.atan2(_d[1], _d[0])
    return Math.cos(midAngle) * 75
  },

  /**
   * 
   */
  setMidAngleWithSin: arc => d => {
    const _d        = arc.centroid(d)
    const midAngle  = Math.atan2(_d[1], _d[0])
    return Math.sin(midAngle) * 75
  },

  /**
   * 
   */
  setFillByNumber: color => i => {
    if(typeof i === 'number') { 
      data[i+1].color = color(i); 
    } 
    return color(i)
  },

  /**
   * 
   */
  setFillName: color => d => {
    return color(d.data.name)
  },

  /**
   * 
   */
  setFillNameWithAngle: color => d => {
    return (d.endAngle - d.startAngle) <= 0.35 ? color(d.data.name) : "#fff"
  },

  /**
   * 
   */
  setFilterByAngle: () => d => {
    return (d.endAngle - d.startAngle) > 0.35
  },

  /**
   * 
   */
  setTextNameWithValue: () => d => {
    return `${ d.data.name }: ${ d.data.value.toLocaleString() }`
  },

  /**
   * 
   */
  setTextPercentWithAngle: () => d => {
    return (~~((d.endAngle - d.startAngle) / (2 * Math.PI) * 10000)) / 100 + '%'
  },

  /**
   * 
   */
  setTransformWithColor: (color, legendRectSize, legendSpacing) => (d, i) => {
    var height = legendRectSize + legendSpacing
    var offset =  height * color.domain().length / 2
    var horz = 21 * legendRectSize
    var vert = i * height - offset
    return 'translate(' + horz + ',' + vert + ')'
  },

  /**
   * 
   */
  transformLegend: arc => d => {      
    const _d = arc.centroid(d);
    if((d.endAngle - d.startAngle) <= 0.35) {
      _d[0] *= 2.2;
      _d[1] *= 2.2;
    } else {
      _d[0] *= 0.7;
      _d[1] *= 0.7;
    }
    return `translate(${_d})`;
  },

    /**
     * 
     */
    async handlePrintClick() {

      // get svg
      let svg1 = document.getElementById('chart-1').innerHTML.replace(/\r?\n|\r/g, '').trim()
      let svg2 = document.getElementById('chart-2').innerHTML.replace(/\r?\n|\r/g, '').trim()
      let svg3 = document.getElementById('chart-3').innerHTML.replace(/\r?\n|\r/g, '').trim()
      let svg4 = document.getElementById('chart-4').innerHTML.replace(/\r?\n|\r/g, '').trim()
      let svg5 = document.getElementById('chart-5').innerHTML.replace(/\r?\n|\r/g, '').trim()
      let svg6 = document.getElementById('chart-6').innerHTML.replace(/\r?\n|\r/g, '').trim()
      let svg7 = document.getElementById('chart-7').innerHTML.replace(/\r?\n|\r/g, '').trim()

      // transform canvas
      let canvas1 = document.createElement('canvas')
      let ctx1    = canvas1.getContext('2d')
      canvas1.setAttribute( 'width','1200')
      canvas1.setAttribute( 'height','600')
      let canvas2 = document.createElement('canvas')
      let ctx2    = canvas2.getContext('2d')
      canvas2.setAttribute( 'width','1200')
      canvas2.setAttribute( 'height','600')
      let canvas3 = document.createElement('canvas')
      let ctx3    = canvas3.getContext('2d')
      canvas3.setAttribute( 'width','1200')
      canvas3.setAttribute( 'height','600')
      let canvas4 = document.createElement('canvas')
      let ctx4    = canvas4.getContext('2d')
      canvas4.setAttribute( 'width','1200')
      canvas4.setAttribute( 'height','600')
      let canvas5 = document.createElement('canvas')
      let ctx5    = canvas5.getContext('2d')
      canvas5.setAttribute( 'width','1200')
      canvas5.setAttribute( 'height','600')
      let canvas6 = document.createElement('canvas')
      let ctx6    = canvas6.getContext('2d')
      canvas6.setAttribute( 'width','1200')
      canvas6.setAttribute( 'height','600')
      let canvas7 = document.createElement('canvas')
      let ctx7    = canvas7.getContext('2d')
      canvas7.setAttribute( 'width','1200')
      canvas7.setAttribute( 'height','600')
      
      // gen canvas
      await canvg.fromString(ctx1, svg1).start()
      await canvg.fromString(ctx2, svg2).start()
      await canvg.fromString(ctx3, svg3).start()
      await canvg.fromString(ctx4, svg4).start()
      await canvg.fromString(ctx5, svg5).start()
      await canvg.fromString(ctx6, svg6).start()
      await canvg.fromString(ctx7, svg7).start()

      // gen img
      let imgData1 = canvas1.toDataURL('image/png', 1.0);
      let imgData2 = canvas2.toDataURL('image/png', 1.0);
      let imgData3 = canvas3.toDataURL('image/png', 1.0);
      let imgData4 = canvas4.toDataURL('image/png', 1.0);
      let imgData5 = canvas5.toDataURL('image/png', 1.0);
      let imgData6 = canvas6.toDataURL('image/png', 1.0);
      let imgData7 = canvas7.toDataURL('image/png', 1.0);
      
      // required
      let providerName      = this.pProvider.businessName
      let providerRut       = this.pProvider.rut
      let providerCategory  = this.pProvider.category.name
      let providerScoreName = this.pProvider.scoreName
      let providerScore     = this.pProvider.scorePercentage
      let providerCriteria  = this.survey.map(section => section.title)
      let providerQuestion = 48 //21 afuera de faena, 48 normal
      let providerImages    = [
        imgData1,
        imgData2,
        imgData3,
        imgData4,
        imgData5,
        imgData6,
        imgData7,
      ]

      // gen pdf
      PProviderService.printReport(providerImages, providerName, providerRut, providerCategory, providerScoreName, providerScore, this.isProvider, providerCriteria, providerQuestion)
    },


    async handlePrintClickOutFaena() {

      // get svg
      let svg11 = document.getElementById('chart-11').innerHTML.replace(/\r?\n|\r/g, '').trim()
      let svg12 = document.getElementById('chart-12').innerHTML.replace(/\r?\n|\r/g, '').trim()
      let svg13 = document.getElementById('chart-13').innerHTML.replace(/\r?\n|\r/g, '').trim()
      let svg14 = document.getElementById('chart-14').innerHTML.replace(/\r?\n|\r/g, '').trim()
      let svg15 = document.getElementById('chart-15').innerHTML.replace(/\r?\n|\r/g, '').trim()
      let svg16 = document.getElementById('chart-16').innerHTML.replace(/\r?\n|\r/g, '').trim()
      //let svg7 = document.getElementById('chart-7').innerHTML.replace(/\r?\n|\r/g, '').trim()

      // transform canvas
      let canvas11 = document.createElement('canvas')
      let ctx11    = canvas11.getContext('2d')
      canvas11.setAttribute( 'width','1200')
      canvas11.setAttribute( 'height','600')
      let canvas12 = document.createElement('canvas')
      let ctx12    = canvas12.getContext('2d')
      canvas12.setAttribute( 'width','1200')
      canvas12.setAttribute( 'height','600')
      let canvas13 = document.createElement('canvas')
      let ctx13    = canvas13.getContext('2d')
      canvas13.setAttribute( 'width','1200')
      canvas13.setAttribute( 'height','600')
      let canvas14 = document.createElement('canvas')
      let ctx14    = canvas14.getContext('2d')
      canvas14.setAttribute( 'width','1200')
      canvas14.setAttribute( 'height','600')
      let canvas15 = document.createElement('canvas')
      let ctx15    = canvas15.getContext('2d')
      canvas15.setAttribute( 'width','1200')
      canvas15.setAttribute( 'height','600')
      let canvas16 = document.createElement('canvas')
      let ctx16    = canvas16.getContext('2d')
      canvas16.setAttribute( 'width','1200')
      canvas16.setAttribute( 'height','600')
      //let canvas7 = document.createElement('canvas')
      //let ctx7    = canvas7.getContext('2d')
      //canvas7.setAttribute( 'width','1200')
      //canvas7.setAttribute( 'height','600')
      
      // gen canvas
      await canvg.fromString(ctx11, svg11).start()
      await canvg.fromString(ctx12, svg12).start()
      await canvg.fromString(ctx13, svg13).start()
      await canvg.fromString(ctx14, svg14).start()
      await canvg.fromString(ctx15, svg15).start()
      await canvg.fromString(ctx16, svg16).start()
      //await canvg.fromString(ctx7, svg7).start()

      // gen img
      let imgData11 = canvas11.toDataURL('image/png', 1.0);
      let imgData12 = canvas12.toDataURL('image/png', 1.0);
      let imgData13 = canvas13.toDataURL('image/png', 1.0);
      let imgData14 = canvas14.toDataURL('image/png', 1.0);
      let imgData15 = canvas15.toDataURL('image/png', 1.0);
      let imgData16 = canvas16.toDataURL('image/png', 1.0);
      //let imgData7 = canvas7.toDataURL('image/png', 1.0);
      
      // required
      let providerNameF      = this.pProvider.businessName
      let providerRutF       = this.pProvider.rut
      let providerCategoryF  = this.pProvider.category.name
      let providerScoreNameF = this.pProvider.scoreNameFaena
      let providerScoreF   = this.pProvider.scorePercentageFaena
      let providerCriteriaF  = this.surveyFueraFaena.map(section => section.title)
      let providerQuestionF = 21 //21 afuera de faena, 48 normal
      let providerImagesF    = [
        imgData11,
        imgData12,
        imgData13,
        imgData14,
        imgData15,
        imgData16,
        //imgData7,
      ]
      //console.log("pprovider:",this.pProvider)
      //console.log("THIS.ispProvider",this.isProvider)

      // gen pdf
      PProviderService.printReport(providerImagesF, providerNameF, providerRutF, providerCategoryF, providerScoreNameF, providerScoreF, this.isProvider, providerCriteriaF, providerQuestionF)
    },
  },
  mounted() {
    console.log("this.show",this.show)
    //console.log("this.esFueraFaena", this.esFueraFaena)
    if(this.esPerfil==1){
      this.show = true
      this.showFueraFaena= true
    }
    if(this.esFaena==1)   this.getSurvey()
    if(this.esFueraFaena==1)   this.getSurveyFueraFaena()
    this.getProvider()
    //this.getSurveyNormal()
    //this.getSurveyFaena()
  },
  watch:{
    'show'() {
      console.log("this.show",this.show)
      if(this.show && this.esPerfil==0){ 
        this.getSurvey()
      }
    },
    'showFueraFaena'() {
      console.log('this.showFueraFaena',this.showFueraFaena)
      if(this.showFueraFaena && this.esPerfil==0){ 
          this.getSurveyFueraFaena()
      }
    }
  },
  props: {

    /**
     * 
     */
    providerId: {
      type: Number,
      default: 0,
    },
    esFueraFaena:{
      type: Number,
      default:0
    },
    esFaena:{
      type: Number,
      default:0
    },
    esPerfil: {
      type: Number,
      default: 0,
    },
  }
}